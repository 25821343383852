
import router from "@/router"
import { ErrorResponse } from "@/types/types"
import axios, { AxiosResponse } from "axios"
import Vue from "vue";
import Component from 'vue-class-component'
import User from "@/interfaces/User";

@Component
export default class VerifyEmailPage extends Vue {

    text = ''

    async created(): Promise<void> {
        document.title = 'Подтверждение E-mail\'а'

        this.$store.state.msg = ''
        this.$store.state.verifySent = true

        this.$store.commit('SET_LOADING', true)
        await Promise.all([this.verify()])
            .finally(() => this.$store.commit('SET_LOADING', false))
    }

    get user(): User {
        return this.$store.getters['Users/getUser']
    }

    async verify(): Promise<void> {
        const { id, hash } = this.$router.currentRoute.params
        const { expires, signature } = this.$router.currentRoute.query
        const relativeUrl = 'email/verify/' + id + '/' + hash + '?expires=' + expires + '&signature=' + signature

        const url = await this.$store.dispatch('getFullUrl', relativeUrl);
        await axios.get<void | ErrorResponse>(
            url,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>(async (res: AxiosResponse<void | ErrorResponse>) => {
                const statusCode = res.request.status

                switch (statusCode) {
                    case 200:
                        this.text = 'E-mail успешно подтвержден.'
                        break
                    case 404:
                    case 401:
                    case 403:
                        this.text = 'Ссылка для подтверждения пароля не корректна или истек срок ее действия.'
                        break
                    case 409:
                        this.text = 'E-mail уже подтвержден.'
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }

    login(): void {
        this.$store.commit('SET_LOADING', true)
        this.$store.dispatch('authenticate')
            .finally(() => {
                router.push({ name: 'login' })
                this.$store.commit('SET_LOADING', false)
            })
    }
}

import { render, staticRenderFns } from "./UserGuidingInit.vue?vue&type=template&id=4dc61066&scoped=true"
import script from "./UserGuidingInit.vue?vue&type=script&lang=ts"
export * from "./UserGuidingInit.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dc61066",
  null
  
)

export default component.exports

import { Component, Vue } from 'vue-property-decorator'
import axios, {AxiosResponse} from "axios";
import User from "@/interfaces/User";

@Component
export default class UserGuidingInit extends Vue {
    async created() {
        await this.waitForInitLoading();
        await this.waitForUser();

        if (!this.$store.getters['Users/userIsAdmin']
            && !this.$store.getters['Users/userIsAdminLite']
            && !this.$store.getters['Users/userIsManager'])
        {
            const surveysCompletedList = this.$store.getters['Users/surveysCompletedList'];

            if (surveysCompletedList.indexOf('guidingInit') === -1
                && this.user.hasVerifyEmail
                && Number(this.user.dateCreated) >= 1740126798
            ) {
                userGuiding.identify('adviz_user_' + this.user.userId, {
                    name: this.user.name,
                    email: this.user.email,
                    phone: this.user.phoneNumber,
                    created_at: Number(this.user.dateCreated) * 1000,
                })

                await this.saveInitState();
            }
        }
    }


    async saveInitState(): Promise<void> {
        let data = {
            "id": "guidingInit",
            "value": "true"
        };

        const url = await this.$store.dispatch('getFullUrl', 'surveys/save-vote');

        await axios.put<void>(
            url + this.$store.getters.hashQuery,
            data,
            {validateStatus: (): boolean => true}
        )
            .then<void, never>((res: AxiosResponse<void>) => {
                const statusCode = res.request.status

                if (statusCode !== 200) {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => {
                console.error(err);
            })
    }


    get user(): User {
        return this.$store.getters['Users/getUser']
    }


    waitForInitLoading(): Promise<void> {
        return new Promise<void>(resolve => {
            const checkInitLoading = () => {
                if (!this.$store.state.initLoading) {
                    resolve();
                } else {
                    setTimeout(checkInitLoading, 1000);
                }
            };
            checkInitLoading();
        });
    }


    async waitForUser(): Promise<void> {
        return new Promise(resolve => {
            const checkUser = () => {
                if (this.$store.getters['Users/hasUser']) {
                    resolve();
                } else {
                    setTimeout(checkUser, 1000);
                }
            };
            checkUser();
        });
    }
}


